import React, { Suspense } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Loader, { FullPageLoader } from "ui/components/loader.atom";
import { Toaster } from "ui/components/ui/toast/toaster";
import PrivateRoutes, { AuthRoutes } from "ui/layout/private-route";
import GetStartedPage from "ui/pages/auth/get-started.page";
import MobileAuthPage from "ui/pages/auth/mobile-auth.page";
import VerifyOTPPage from "ui/pages/auth/verify-otp.page";
import ErrorPage from "ui/pages/error/error-page";
import GameGuidelinePage from "ui/pages/guideline/game-guideline.page";
import GamePage from "ui/pages/game-page";
import UpdateProfilePage from "ui/pages/profile/update-profile.page";
import WinHistoryPage from "../win-history-page";
import NotFoundPage from "../error/not-found.page";
// import GameWinnersPage from "../game/game/game-winners/game-winners.page";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        errorElement={
          <Suspense fallback={<FullPageLoader />}>
            <ErrorPage />
          </Suspense>
        }
        element={<Outlet />}>
        <Route path="/auth" element={<AuthRoutes />}>
          <Route
            path=""
            element={
              <Suspense fallback={<FullPageLoader />}>
                <GetStartedPage />
              </Suspense>
            }
          />
          <Route
            path="login"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <MobileAuthPage />
              </Suspense>
            }
          />
          <Route
            path="verify-otp"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <VerifyOTPPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <GamePage />
              </Suspense>
            }
          />
          <Route
            path="update-profile"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <UpdateProfilePage />
              </Suspense>
            }
          />
          <Route
            path="guideline"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <GameGuidelinePage />
              </Suspense>
            }
          />
          {/* <Route
            path="game/:gameId/winners"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <GameWinnersPage />
              </Suspense>
            }
          /> */}
          <Route
            path="win-history"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <WinHistoryPage />
              </Suspense>
            }
          />
        </Route>
        {/* NOT FOUND ROUTE */}
        <Route
          path="*"
          element={
            <Suspense fallback={<FullPageLoader />}>
              <NotFoundPage />
            </Suspense>
          }
        />
      </Route>
    )
  );

  return (
    <div
      key="App"
      className="App h-full min-h-screen w-full bg-background text-secondary-foreground">
      <RouterProvider router={router} fallbackElement={<Loader />} />
      <Toaster />
    </div>
  );
}

export default App;
