import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "lib/http-client";

export const getNextGameAsync = createAsyncThunk(
  "game/getNextGame",
  async () => {
    const resource = await httpClient.GET<any>(
      `/game/next`,
    );
    return resource.data;
  }
);