import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNextGameAsync } from "store/reducers/game/game.action";
import { getProfileAsync } from "store/reducers/user/user.action";

export default function AuthApp({ children }: { children: React.ReactNode }) {
  const { status, user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  // Get user profile
  useEffect(() => {
    if (status === "idle" && !user) {
      dispatch(getProfileAsync()).then((result) => {
        if (getProfileAsync.fulfilled.match(result)) {
          if (result.payload["user"]) {
            if (!result.payload["user"]["username"]) {
              router("/update-profile");
            }
          } else {
            router("/auth/mobile-auth", { replace: true });
          }
        }
      });
    }
  }, [dispatch, router, status, user]);

  // Get next game
  useEffect(() => {
    dispatch(getNextGameAsync());
  }, [dispatch]);

  return <>{children}</>;
}
