import React, { SVGProps } from "react";

export default function Logo(prop: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="120"
      height="137"
      viewBox="0 0 120 137"
      fill="none"
      {...prop}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M92.5366 28.2943C106.307 34.8304 114.558 45.865 118.474 60.3053C121.1 69.9858 120.178 79.5447 116.923 88.958C112.204 102.605 107.531 116.268 102.845 129.927C100.625 136.401 97.0629 137.439 91.6727 133.159C88.8388 130.909 86.0106 128.651 83.1857 126.389C80.2286 124.022 77.2431 123.69 73.8794 125.753C68.9892 128.752 63.9566 131.521 58.9839 134.386C52.4962 138.124 48.3304 137.885 42.412 133.462C39.0134 130.922 35.5165 128.505 32.2185 125.842C29.5566 123.692 27.0632 123.368 23.9936 125.07C19.0877 127.791 13.9872 130.159 9.02015 132.772C6.44893 134.126 3.97526 134.392 1.73968 132.362C-0.374196 130.444 -0.298181 128.044 0.580369 125.474C8.67198 101.801 16.6877 78.1025 24.8287 54.4466C25.7519 51.7643 27.3104 49.2997 28.8932 46.674C31.4716 47.8426 33.6601 49.9414 36.0041 50.1314C42.1688 50.6312 48.4894 50.9647 54.5878 50.1588C66.3245 48.6079 77.4232 44.8574 86.9927 37.544C90.024 35.2273 92.5806 32.5047 92.5366 28.2943ZM72.7894 74.1361C69.6686 66.2306 62.951 63.4081 56.9252 67.4705C50.3687 71.8907 48.0836 82.1101 52.1465 88.842C55.5006 94.3994 62.1564 95.8561 67.2725 91.7937C72.7744 87.425 74.4287 81.5415 72.7894 74.1361ZM84.8765 77.3518C84.8561 79.5884 84.5429 81.8694 84.8758 84.0522C85.579 88.6629 87.958 92.2896 92.6292 93.6495C97.3579 95.0261 101.198 93.0867 104.174 89.4429C108.341 84.3402 108.978 76.8216 105.871 71.1946C102.614 65.294 95.2168 63.7372 90.3544 68.4072C88.0391 70.6309 86.7509 73.9194 84.8765 77.3518Z"
        fill="#8150F0"
      />
      <path
        d="M92.4597 28.0315C92.5806 32.5047 90.024 35.2273 86.9927 37.544C77.4232 44.8574 66.3245 48.6079 54.5878 50.1588C48.4894 50.9647 42.1688 50.6312 36.0041 50.1314C33.6601 49.9414 31.4716 47.8426 29.0455 46.4259C26.553 37.7187 24.1718 29.2136 21.9617 20.6646C21.6323 19.3907 21.6749 17.5938 22.3725 16.6431C22.7905 16.0735 25.022 16.2755 26.0806 16.8215C31.0033 19.3606 35.801 22.1408 40.1937 24.5845C42.9248 20.0192 45.8568 15.0215 48.8905 10.086C50.5415 7.39996 53.0767 6.37486 55.9674 7.67515C60.1388 9.55154 64.1867 11.7148 68.2113 13.8954C69.5948 14.645 70.7503 15.8136 71.9247 16.7245C74.186 12.4677 76.2663 8.4251 78.4823 4.45796C79.1398 3.28088 80.0857 2.22041 81.0622 1.27356C83.1794 -0.77931 84.7392 -0.351023 85.5463 2.56301C87.8699 10.9525 90.1099 19.3651 92.4597 28.0315Z"
        fill="#FFBD53"
      />
      <path
        d="M72.841 74.4362C74.4287 81.5415 72.7744 87.425 67.2725 91.7937C62.1564 95.8561 55.5006 94.3994 52.1465 88.842C48.0836 82.1101 50.3687 71.8907 56.9252 67.4705C62.951 63.4081 69.6686 66.2306 72.841 74.4362Z"
        fill="#FDFCFF"
      />
      <path
        d="M84.937 77.0387C86.7509 73.9194 88.0391 70.6309 90.3544 68.4072C95.2168 63.7372 102.614 65.294 105.871 71.1946C108.978 76.8216 108.341 84.3402 104.174 89.4429C101.198 93.0867 97.3579 95.0261 92.6292 93.6495C87.958 92.2896 85.579 88.6629 84.8758 84.0522C84.5429 81.8694 84.8561 79.5884 84.937 77.0387Z"
        fill="#FDFDFF"
      />
    </svg>
  );
}
