
export type Result<T> = {
  ok: boolean;
  data: T;
  error?: any;
  response?: Response;
};


const BASE_URL = process.env.REACT_APP_BASE_URL;
class HttpClient {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  async GET<T>(url: string): Promise<Result<T>> {
    const token = localStorage.getItem("token");
    const headers = new Headers({
      "Content-Type": "application/json",
      "Client-Type": "web",
    });

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    const response = await fetch(this.baseUrl + url, {
      method: "GET",
      headers: headers,
    });
    const data = await response.json();
    if (!response.ok) {
      console.error("Error fetching " + url, data);
      if (data.error) {
        throw new Error(data.error);
      } else if (data.message) {
        throw new Error(data.message);
      } else {
        throw new Error("Unable to perform operation");
      }
    } else if (!data.data && data.error) {
      throw new Error(data?.error?.message ?? "Unable to perform operation");
    }
    return { ok: response.ok, data: data, response: response };
  }

  async POST<T>(url: string, data?: any): Promise<Result<T>> {
    const token = localStorage.getItem("token");
    const headers = new Headers({
      "Client-Type": "web",
    });
    // Check id data is form data then set content type to multipart/form-data
    if (!(data instanceof FormData)) {
      headers.set("Content-Type", "application/json");
    }
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    const response = await fetch(this.baseUrl + url, {
      method: "POST",
      headers: headers,
      body: !(data instanceof FormData) ? JSON.stringify(data) : data,
    });
    const map = await response.json();
    if (!response.ok) {
      console.error("Error fetching " + url, data);
      if (map.error) {
        throw new Error(map.error);
      } else if (map.message) {
        throw new Error(map.message);
      } else {
        throw new Error("Unable to perform operation");
      }
    }
    return { ok: response.ok, data: map, response: response };
  }

  async DELETE(url: string): Promise<Result<any>> {
    const token = localStorage.getItem("token");
    const headers = new Headers({
      "Content-Type": "application/json",
    });

    const response = await fetch(this.baseUrl + url, {
      method: "DELETE",
      headers: headers,
    });
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    const map = await response.json();

    if (!response.ok) {
      console.error("Error fetching " + url, map);
      if (map.error) {
        throw new Error(map.error);
      } else if (map.message) {
        throw new Error(map.message);
      } else {
        throw new Error("Unable to perform operation");
      }
    }

    return { ok: response.ok, data: map, response: response };
  }
}

const httpClient = new HttpClient(BASE_URL ?? "");

export { httpClient };
