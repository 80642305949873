import React from "react";
import { WinningHistory } from "types/win-history.type";

export default function WinHistory({
  winHistoryList,
}: {
  winHistoryList?: WinningHistory[];
}) {
  return (
    <div className="flex flex-col">
      <h1 className="py-2 text-center text-xl font-medium">Winning history</h1>
      {winHistoryList && winHistoryList?.length > 0 ? (
        <div className="grid grid-cols-2 place-content-center items-center gap-1 sm:grid-cols-3 sm:gap-3 sm:px-4">
          {winHistoryList?.map((win, i) => <WinCard key={i} win={win} />)}
        </div>
      ) : (
        <div className="flex h-40 place-content-center items-center text-center text-muted-foreground">
          No winnings yet
        </div>
      )}
    </div>
  );
}

function WinCard({ win }: { win: WinningHistory }) {
  return (
    <div className="relative m-2 flex aspect-square flex-col place-content-center items-center rounded bg-primary p-4 text-secondary">
      <p className="mb-4 w-full text-center text-2xl font-medium">
        {win.gameTitle}
      </p>
      {/* Date and Time */}
      <p className="absolute right-4 top-4 text-xs font-medium text-primary-foreground/80">
        {new Date(win.gameStartTime).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        })}{" "}
        {new Date(win.gameStartTime).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
        })}
      </p>
      <p className="absolute bottom-4 left-4 pt-2 text-xl font-bold">
        ₹{Math.round(win.userPrizeMoney * 100) / 100}
      </p>
    </div>
  );
}
