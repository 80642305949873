import { cn } from "lib/utils";
import React from "react";
import { FullPageLoader } from "ui/components/loader.atom";

interface Props {
  children: React.ReactNode;
  /**
   * Appbar to show on top
   */
  appbar?: React.ReactNode;
  appbarSecondary?: React.ReactNode;
  /**
   * Component to display at the bottom of the appbar
   */
  appBarBottom?: React.ReactNode;
  /**
   * Show a placeholder while loading
   */
  isLoading?: boolean;
  /**
   * Placeholder to show while loading
   */
  placeholder?: React.ReactNode;

  /**
   * Page title
   */
  title?: string;
  /**
   * track scroll position
   */
  trackEvent?: (eventName: any, extra?: any) => void;

  /**
   * className to add to the main container
   */
  className?: string;

  /**
   * inner className to add to the children container
   */
  innerClassName?: string;
}

/**
 * Master Page Layout
 * @param children
 *
 */
export default function MobilePageLayout({
  children,
  appbar,
  appbarSecondary,
  appBarBottom,
  isLoading = false,
  placeholder,
  title,
  className,
  innerClassName,
}: Props) {
  React.useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return (
    <main
      id={title}
      key={title}
      role="main"
      className={cn(
        "MobilePageLayout h-full min-h-screen w-full text-secondary-foreground",
        className
      )}>
      {isLoading ? (
        (placeholder ?? <FullPageLoader />)
      ) : (
        <>
          <div
            className={cn(
              "mx-auto flex h-full min-h-screen w-full max-w-[720px] flex-col shadow-xl",
              innerClassName
            )}>
            {appbar}
            {appbarSecondary && appbarSecondary}
            {appBarBottom && appBarBottom}
            {children}
          </div>
        </>
      )}
    </main>
  );
}
