import React from "react";
import { Provider } from "react-redux";
import store from "store/root-reducer";

interface Props {
  children: any;
}

/**
 * Wraps all the providers in the app.
 * @param {ReactNode} children
 * @returns {JSX.Element}
 */

export default function Providers({ children }: Props): JSX.Element {
  return <Provider store={store}>{children}</Provider>;
}
