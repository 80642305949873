import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "lib/http-client";

export const getQuestionAsync = createAsyncThunk(
  "question/getQuestion",
  async ({ index, gameId, isInitialLoad }: { index: number, gameId: string, isInitialLoad?: boolean }) => {
    const resource = await httpClient.GET<any>(
      `/game/${gameId}/question/${index}`,
    );
    return resource.data;
  }
);

export const submitAnswerAsync = createAsyncThunk(
  "question/submitAnswer",
  async ({ gameId, index, answer }: { gameId: string, index: number, answer: string }) => {
    const resource = await httpClient.POST<any>(`/game/${gameId}/question/${index}/answer`, { answer });
    return resource.data;
  }
);
