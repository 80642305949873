import React from "react";

export default function Loader({ size = 24 }) {
  return (
    <div
      className="border-default box-border block animate-spin rounded-full border-2 border-t-primary"
      style={{
        width: size,
        minWidth: size,
        height: size,
        minHeight: size,
      }}></div>
  );
}

export function FullPageLoader() {
  return (
    <div className="theme-bg-default flex h-screen w-full place-content-center items-center">
      <Loader />
    </div>
  );
}
