import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { User } from "types/user.type";
import { z } from "zod";
import { Status } from "../base.state";
import { getProfileAsync, getUserLastWinningAmountAsync, updateProfileAsync } from "./user.action";



export interface UserRState {
  user?: User
  status: Status;
  updateStatus: Status;
  lastWinningAmountStatus: Status;
  lastWinnings: number;
}

const initialState: UserRState = {
  status: "idle",
  updateStatus: "idle",
  lastWinningAmountStatus: "idle",
  lastWinnings: 0,
};

const reducers = {
  updateState: (
    state: UserRState,
    action: PayloadAction<Partial<UserRState>>
  ) => {
    return { ...state, ...action.payload };
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<UserRState>) => {
  // get profile
  builder.addCase(getProfileAsync.pending, (state) => {
    state.status = "loading";
  })

  builder.addCase(getProfileAsync.fulfilled, (state, action) => {
    state.status = "success";
    state.user = action.payload['user'];
  })

  builder.addCase(getProfileAsync.rejected, (state) => {
    state.status = "failed";
  })
  // update profile
  builder.addCase(updateProfileAsync.pending, (state) => {
    state.updateStatus = "loading";
  })
  builder.addCase(updateProfileAsync.fulfilled, (state, action) => {
    state.updateStatus = "success";
    state.user = action.payload['user'];
  }
  )
  builder.addCase(updateProfileAsync.rejected, (state) => {
    state.updateStatus = "failed";
  })

  // get last winning amount
  builder.addCase(getUserLastWinningAmountAsync.pending, (state) => {
    state.lastWinningAmountStatus = "loading";
  })
  builder.addCase(getUserLastWinningAmountAsync.fulfilled, (state, action) => {
    state.lastWinnings = action.payload['lastWinnings'];
    state.lastWinningAmountStatus = "success";
  })
  builder.addCase(getUserLastWinningAmountAsync.rejected, (state) => {
    state.lastWinningAmountStatus = "failed";
  })
};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

export const actions = UserSlice.actions;

const UserReducer = UserSlice.reducer;

export default UserReducer;
