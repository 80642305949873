import { ArrowLeft } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import MobilePageLayout from "ui/layout/mobile-page-layout";

export default function NotFoundPage() {
  return (
    <MobilePageLayout
      className="relative bg-gradient-to-b from-primary to-[#282061]"
      innerClassName="min-h-screen bg-primary  text-secondary border border-secondary/5 rounded">
      <div className="flex h-screen w-full flex-col place-content-center items-center">
        <div className="py-4 text-center text-xl font-medium">
          <h1 className="font-family-app-label text-4xl tracking-wider text-secondary">
            Poobla
          </h1>
        </div>
        <div className="my-40 rounded bg-slate-50/10 p-10 text-secondary">
          <h1 className="font-semibold">Oops!</h1>
          <p className="text-lg font-medium">
            Sorry, the page you are looking for does not exist.
          </p>
          <p className="mt-4 font-light">
            <i>404 Not Found</i>
          </p>
        </div>

        <div className="mt-10">
          <Link to="/" className="group flex text-secondary hover:underline">
            <ArrowLeft className="mr-2 translate-x-0 transition-transform duration-150 ease-linear group-hover:-translate-x-1" />{" "}
            Go to home
          </Link>
        </div>
      </div>
    </MobilePageLayout>
  );
}
