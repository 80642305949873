import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "lib/http-client";

export const loginWithMobileAsync = createAsyncThunk(
  "auth/loginWithMobile",
  async (args: { phoneNumber?: string }) => {
    const resource = await httpClient.POST<any>(
      '/auth/otp/get',
      args
    );
    return resource.data;
  }
);

export const verifyOTPAsync = createAsyncThunk("auth/verifyOtp", async (args: { otp: string, phoneNumber?: string }) => {
  const resource = await httpClient.POST<any>('/auth/otp/verify', args);
  return resource.data;
});