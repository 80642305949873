import { motion, useAnimation, Variants } from "framer-motion";
import { cn } from "lib/utils";
import React, { SVGProps } from "react";

const arrowVariants: Variants = {
  normal: { x: 0 },
  animate: {
    x: 3,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 10,
      mass: 1,
    },
  },
};

const LogoutIcon = ({
  className,
  iconProp,
}: {
  className?: string;
  iconProp?: SVGProps<SVGSVGElement>;
}) => {
  const controls = useAnimation();

  return (
    <div
      className={cn(
        "flex cursor-pointer select-none items-center justify-center",
        className
      )}
      onMouseEnter={() => controls.start("animate")}
      onMouseLeave={() => controls.start("normal")}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...iconProp}>
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
        <motion.g variants={arrowVariants} animate={controls}>
          <polyline points="16 17 21 12 16 7" />
          <line x1="21" x2="9" y1="12" y2="12" />
        </motion.g>
      </svg>
    </div>
  );
};

export { LogoutIcon };
