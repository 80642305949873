import { motion, useAnimation, Variants } from "framer-motion";
import { cn } from "lib/utils";
import React, { SVGProps } from "react";

const variants: Variants = {
  normal: { rotate: 0 },
  animate: { rotate: [0, -10, 10, -10, 0] },
};

const QuestionMarkIcon = ({
  className,
  iconProp,
}: {
  className?: string;
  iconProp?: SVGProps<SVGSVGElement>;
}) => {
  const controls = useAnimation();

  return (
    <div
      className={cn(
        "flex cursor-pointer select-none items-center justify-center",
        className
      )}
      onMouseEnter={() => controls.start("animate")}
      onMouseLeave={() => controls.start("normal")}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...iconProp}>
        <motion.g
          variants={variants}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
          animate={controls}>
          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
          <path d="M12 17h.01" />
        </motion.g>
      </svg>
    </div>
  );
};

export { QuestionMarkIcon };
