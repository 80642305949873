import { motion, useAnimation, Variants } from "framer-motion";
import { cn } from "lib/utils";
import React, { SVGProps } from "react";

const variants: Variants = {
  normal: { pathLength: 1, opacity: 1, pathOffset: 0 },
  animate: {
    pathLength: [0, 1],
    opacity: [0, 1],
    pathOffset: [1, 0],
  },
};

const UserIcon = ({
  className,
  iconProp,
}: {
  className?: string;
  iconProp?: SVGProps<SVGSVGElement>;
}) => {
  const controls = useAnimation();

  return (
    <div
      className={cn(
        "flex cursor-pointer select-none items-center justify-center",
        className
      )}
      onMouseEnter={() => controls.start("animate")}
      onMouseLeave={() => controls.start("normal")}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...iconProp}>
        <circle cx="12" cy="8" r="5" />
        <motion.path
          d="M20 21a8 8 0 0 0-16 0"
          variants={variants}
          transition={{
            delay: 0.2,
            duration: 0.4,
          }}
          animate={controls}
        />
      </svg>
    </div>
  );
};

export { UserIcon };
