import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { getInitials } from "lib/functions/get-initials";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "ui/components/ui/avatar";
import { Button } from "ui/components/ui/button";
import MobilePageLayout from "ui/layout/mobile-page-layout";

import {
  ANSWER_WAIT_DURATION,
  NEXT_QUESTION_WAIT_DURATION,
  Sounds,
} from "constants/game.constants";
import { playSound } from "lib/functions/play-sound";
import { httpClient } from "lib/http-client";
import { cn } from "lib/utils";
import { ArrowLeft } from "lucide-react";
import { getUserLastWinningAmountAsync } from "store/reducers/user/user.action";
import { Question, Winner } from "types";
import { Game } from "types/game.type";
import { GameStartCountDownTimer } from "ui/components/game-start-countdown-timer";
import { LogoutIcon, UserIcon, QuestionMarkIcon } from "ui/components/icons";
import { QuizCountDownTimer } from "ui/components/quiz-countdown-timer";
import QuestionPrompt from "./component/question-prompt";

type GAME_PHASE =
  | "display-info"
  | "game-countdown-start"
  | "game-start"
  | "display-question"
  | "submit-answer"
  | "display-answer"
  | "wait-next-question"
  | "game-over";

export default function GamePage() {
  // user state
  const { status, user } = useAppSelector((state) => state.user);
  // game state
  const [game, setGame] = useState<Game | null>(null);
  const [phase, setPhase] = useState<GAME_PHASE>("display-info");
  const [countdown, setCountdown] = useState(0);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [result, setResult] = useState<Result>();
  const [totalUserCount, setTotalUserCount] = useState(1);
  const [isSpectator, setIsSpectator] = useState(false);
  const [isUserOut, setIsUserOut] = useState(false);
  const [wasUserOut, setWasUserOut] = useState(false);

  // question state
  const [question, setQuestion] = useState<Question | null>(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState<string>(""); // save the correct answer for current question ongoing, takes from ws
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0); // saves the user attempts which were correct
  const [questionTimeLeft, setQuestionTimeLeft] = useState(-1); // time left for current question after resync

  function increaseCorrectAnswerCount() {
    setCorrectAnswerCount(correctAnswerCount + 1);
  }

  function markUserOut() {
    setIsUserOut(true);
    setIsSpectator(true);
    // set the user out in sessionStorage
    sessionStorage.setItem(game?.id + ":userOut", true.toString());
  }

  function resetGame() {
    setGame(null);
    setPhase("display-info");
    setIsGameStarted(false);
    setResult(undefined);
    setTotalUserCount(1);
    setIsSpectator(false);
    setIsUserOut(false);
    setWasUserOut(false);
    setCountdown(0);
    setCorrectAnswerCount(0);
    // Fetch the latest winning amount on game reset
    dispatch(getUserLastWinningAmountAsync());
  }

  const userId = user?.id;
  const gameId = game?.id;
  // ws
  useEffect(() => {
    const wsUrl =
      process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:3000/ws";
    if (userId && wsUrl) {
      const ws = new WebSocket(wsUrl);
      ws.onopen = () => {
        ws.send(
          JSON.stringify({ eventName: "connect-user", data: { userId } })
        );
      };
      ws.onmessage = (event) => {
        const payload = JSON.parse(event.data);
        const { eventName, data } = payload;
        console.log("Event received: ", eventName, "Data: ", data);
        switch (eventName) {
          case "new-game-info":
          case "game-sync":
            setGame(data.game);
            setPhase(data.phase);
            if (
              (data.game && data.phase !== "display-info") ||
              data.phase === "game-over"
            ) {
              setIsGameStarted(true);
            }
            // resume the game
            if (
              [
                "display-question",
                "submit-answer",
                "display-answer",
                "wait-next-question",
              ].includes(data.phase)
            ) {
              setPhase(data.phase);
              setQuestion(data.question);
              setQuestionIndex(data.questionIndex);
              setQuestionTimeLeft(Math.floor(data.timeLeft / 1000));
            }
            // resync the answers
            if (data.game && data.questionIndex > 0) {
              const gameId = data.game.id;
              let answeredCount = 0;
              for (let i = 0; i < data.questionIndex; i++) {
                const isAnswered = Boolean(
                  sessionStorage.getItem(
                    gameId + ":answeredIndex:" + i.toString()
                  )
                );
                if (isAnswered) {
                  answeredCount++;
                }
              }
              console.log("Answered count: ", answeredCount);
              setCorrectAnswerCount(answeredCount);
            }
            break;
          case "game-start":
            setPhase("game-start");
            setIsGameStarted(true);
            break;
          case "display-question":
            setPhase("display-question");
            setQuestion(data.question);
            setQuestionIndex(data.questionIndex);
            break;
          case "submit-answer":
            setPhase("submit-answer");
            break;
          case "display-answer":
            setPhase("display-answer");
            setCorrectAnswer(data.correctAnswer);
            break;
          case "wait-next-question":
            setPhase("wait-next-question");
            setCorrectAnswer("");
            break;
          case "info-message":
            console.log("Info message received: ", data);
            break;
          case "game-over":
            setPhase("game-over");
            setWasUserOut(true);
            break;
          case "winner-list":
            setResult(data);
            break;
          case "total-users-sync":
            setTotalUserCount(data);
            break;
        }
      };
      ws.onclose = () => {
        console.log("WebSocket connection closed");
      };
    }
  }, [userId]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserLastWinningAmountAsync());
  }, [dispatch]);

  // countdown timer
  useEffect(() => {
    if (game) {
      const interval = setInterval(() => {
        const time = Math.floor(
          (new Date(game.startTime).getTime() - new Date().getTime()) / 1000
        );
        if (time <= 0) {
          clearInterval(interval);
        } else {
          setCountdown(time);
        }
      }, 1000);

      // check if user is out
      const wasUserOut = Boolean(sessionStorage.getItem(game.id + ":userOut"));
      if (wasUserOut) {
        setWasUserOut(true);
        setIsSpectator(true);
      }

      // clear interval
      return () => clearInterval(interval);
    }
    return () => {};
  }, [game]);

  return (
    <MobilePageLayout
      title="Home"
      isLoading={status === "loading"}
      className="bg-gradient-to-b from-primary to-[#282061]">
      <div
        className={
          isGameStarted || true
            ? "relative text-secondary"
            : "flex min-h-screen w-full flex-col items-center bg-primary p-4"
        }>
        {/* header options */}
        {!isGameStarted && (
          <div className="absolute right-4 top-4 flex gap-2">
            <Link to="/guideline" title="Guideline">
              <button className="flex h-6 w-6 items-center justify-center rounded-full bg-secondary/40 text-secondary hover:bg-secondary/60 active:scale-95 active:bg-secondary/80 active:text-primary active:shadow-sm">
                <QuestionMarkIcon
                  iconProp={{ height: 20, strokeWidth: 1.5 }}
                  className="text-destructive-foreground"
                />
              </button>
            </Link>
            <Link to="/update-profile?redirect=/" title="Profile">
              <button className="flex h-6 w-6 items-center justify-center rounded-full bg-secondary/40 text-secondary hover:bg-secondary/60 active:scale-95 active:bg-secondary/80 active:text-primary active:shadow-sm">
                {/* <UserIcon className="h-3 w-3" /> */}
                <UserIcon
                  className="h-3 w-3"
                  iconProp={{ height: 20, strokeWidth: 3 }}
                />
              </button>
            </Link>
            <Link to="auth" title="Logout">
              <button
                className="flex h-6 w-6 items-center justify-center rounded-full bg-destructive/60 text-secondary hover:bg-destructive/80 active:scale-95 active:bg-destructive active:shadow-sm"
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                }}>
                <LogoutIcon
                  iconProp={{ height: 10, strokeWidth: 3 }}
                  className="h-6 w-6 text-destructive-foreground"
                />
              </button>
            </Link>
          </div>
        )}
        <div
          className={cn(
            "flex min-h-screen w-full flex-col items-center bg-primary p-4",
            {
              "md:pt-10": !isGameStarted,
            }
          )}>
          {/* title */}
          <div
            className={cn("transition-all duration-1000", {
              "py-4 text-center text-4xl font-medium transition-opacity":
                !isGameStarted,
              "flex w-full justify-between text-left": isGameStarted,
              "opacity-0":
                countdown <= 15 && phase !== "display-info" && !isGameStarted,
            })}>
            {isGameStarted && (
              <p className="flex items-center justify-center gap-1">
                <UserIcon className="h-4 w-4" />{" "}
                <span>{totalUserCount.toLocaleString()}</span>
              </p>
            )}
            <h1 className="font-family-app-label tracking-wider text-secondary">
              Poobla
            </h1>
          </div>
          {/* game loader */}
          {!game && <NextGameStartTime />}
          {game && (
            <>
              {phase === "display-info" &&
                !isGameStarted &&
                (countdown > 60 * 60 * 24 ? (
                  <div className="flex flex-col items-center gap-2 py-10">
                    <p className="text-md">NEXT GAME</p>
                    <div className="flex flex-col items-center">
                      {/* Display start date if game start tomorrow or later */}
                      {game?.startTime &&
                        game.startTime > new Date().toISOString() && (
                          <p className="text-2xl">
                            {new Date(game.startTime).toLocaleDateString(
                              "en-IN",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </p>
                        )}
                      {/* Display start time if game start time is not midnight */}
                      {game?.startTime && (
                        <p className="text-2xl">
                          {new Date(game.startTime).toLocaleTimeString(
                            "en-IN",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </p>
                      )}
                    </div>
                    <p className="text-2xl"></p>
                    <p className="text-2xl">₹{game?.prizeMoney} prize </p>
                  </div>
                ) : countdown > 60 * 15 ? (
                  <NextGameStartTime />
                ) : countdown > 0 ? (
                  // Display the countdown timer if game starts in less than 15 min
                  <GameStartCountDownTimer timeLeft={countdown} />
                ) : (
                  <div className="h-28"></div>
                ))}
            </>
          )}
          {/* </div> */}
          {!isUserOut && (
            <QuestionComponent
              gameId={gameId ?? ""}
              question={question}
              questionIndex={questionIndex}
              phase={phase}
              isSpectator={isSpectator}
              wasUserOut={wasUserOut}
              correctAnswer={correctAnswer}
              increaseCorrectAnswerCount={increaseCorrectAnswerCount}
              markUserOut={markUserOut}
              questionTimeLeft={questionTimeLeft}
              resetQuestionTimeLeft={() => setQuestionTimeLeft(-1)}
            />
          )}

          {/* game component */}
          {game && isUserOut && !wasUserOut && (
            <GameOverScreen
              correctAnswerCount={correctAnswerCount}
              totalQuestionCount={game.totalQuestionCount ?? 0}
              onContinueWatching={() => {
                setIsUserOut(false);
                setWasUserOut(true);
                setIsSpectator(true);
              }}
            />
          )}

          {game && phase === "game-over" && wasUserOut && (
            <WinnerList
              result={result}
              resetGame={resetGame}
              prizeMoney={game.prizeMoney}
            />
          )}

          {/* user details */}
          {countdown > 15 || (countdown === 0 && !isGameStarted) ? (
            <UserDetailsCard />
          ) : (
            <></>
          )}
        </div>
      </div>
    </MobilePageLayout>
  );
}

function UserDetailsCard() {
  const { user, lastWinnings } = useAppSelector((state) => state.user);

  return (
    <>
      <div
        className={`mx-auto flex aspect-square w-full max-w-sm flex-col place-content-between items-center rounded-2xl bg-card py-4 text-secondary-foreground shadow-md`}>
        <Link
          to="/update-profile?redirect=/"
          title="Profile"
          className="flex flex-grow flex-col">
          <div className="flex flex-grow flex-col place-content-center items-center gap-4">
            <Avatar className="h-20 w-20">
              <AvatarImage src={user?.picture} />
              <AvatarFallback>{getInitials(user?.name)}</AvatarFallback>
            </Avatar>
            <h1 className="text-xl">{user?.username}</h1>
          </div>
        </Link>
        <Link
          to={"win-history"}
          className="flex w-full flex-grow flex-col place-content-center items-center gap-4 border-t border-border">
          <p>BALANCE </p>
          <p className="text-3xl font-bold">
            ₹{Math.round(lastWinnings! * 100) / 100}
          </p>
        </Link>
      </div>

      <Button className="absolute bottom-5 left-0 right-0 mx-auto max-w-sm rounded-full bg-secondary/15 hover:bg-secondary/20">
        <Link to="/auth">
          <h1 className="">Invite</h1>
        </Link>
      </Button>
    </>
  );
}

function QuestionComponent({
  gameId,
  question,
  questionIndex,
  phase,
  isSpectator,
  wasUserOut,
  correctAnswer,
  increaseCorrectAnswerCount,
  markUserOut,
  questionTimeLeft,
  resetQuestionTimeLeft,
}: {
  gameId: string;
  question: Question | null;
  questionIndex: number;
  phase: GAME_PHASE;
  isSpectator: boolean;
  wasUserOut: boolean;
  correctAnswer: string;
  increaseCorrectAnswerCount: () => void;
  markUserOut: () => void;
  questionTimeLeft: number;
  resetQuestionTimeLeft: () => void;
}) {
  const allowedPhases = [
    "display-question",
    "submit-answer",
    "display-answer",
    "wait-next-question",
  ];

  // state
  const [selectedOption, setSelectedOption] = useState<string>();

  // effect
  useEffect(() => {
    if (phase === "submit-answer") {
      submitAnswer();
    }
  }, [phase]);
  useEffect(() => {
    if (correctAnswer && selectedOption === correctAnswer) {
      increaseCorrectAnswerCount();
    }
  }, [correctAnswer, selectedOption]);

  // Check if user was out in the previous question
  // If user was out, then set the selected option to the last incorrect option
  // Displays the last incorrect option selected by the user in red
  useEffect(() => {
    const key = `${gameId}:answeredIndex:${questionIndex}:incorrectOption:`;
    const lastIncorrectOption = sessionStorage.getItem(key);
    if (lastIncorrectOption) {
      setSelectedOption(lastIncorrectOption ?? "");
    } else if (wasUserOut && selectedOption) {
      sessionStorage.removeItem(key);
      setSelectedOption(undefined);
      console.log("Set selected option to undefined");
    }
  }, [gameId, questionIndex, selectedOption, wasUserOut]);

  async function submitAnswer() {
    if (isSpectator) return;
    if (!selectedOption) {
      markUserOut();
      return;
    }
    // do the api call for submit answer
    try {
      const response = await httpClient.POST<any>(
        `/game/${gameId}/question/${questionIndex}/answer`,
        { answer: selectedOption }
      );
      console.log("Answer submitted: ", response);
      // save to session storage
      sessionStorage.setItem(
        gameId + ":answeredIndex:" + questionIndex.toString(),
        true.toString()
      );
    } catch (error: any) {
      if (error.message === "Wrong answer") {
        // mark user as out
        sessionStorage.setItem(
          `${gameId}:answeredIndex:${questionIndex}:incorrectOption:`,
          selectedOption
        );
        markUserOut();
      } else {
        console.log("Error submitting answer: ", error);
      }
    }
  }

  if (!question || !allowedPhases.includes(phase)) {
    return <></>;
  }

  if (phase === "display-question" || phase === "display-answer") {
    return (
      <QuestionPrompt
        question={question}
        revealAnswer={phase === "display-answer"}
        correctAnswer={correctAnswer}
        isSpectator={isSpectator}
        wasUserOut={wasUserOut}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        questionTimeLeft={questionTimeLeft}
        resetQuestionTimeLeft={resetQuestionTimeLeft}
      />
    );
  }

  if (phase === "submit-answer") {
    return (
      <>
        <div className="flex h-full w-full flex-grow flex-col place-content-center items-center gap-4 text-7xl text-secondary">
          <p className="text-2xl">Result in</p>
          <QuizCountDownTimer countdownStartFrom={ANSWER_WAIT_DURATION} />
        </div>
      </>
    );
  }

  if (phase === "wait-next-question") {
    return (
      <div className="flex h-full w-full flex-grow flex-col place-content-center items-center gap-4 text-7xl text-secondary">
        <p className="text-2xl">Next Question in</p>
        <QuizCountDownTimer countdownStartFrom={NEXT_QUESTION_WAIT_DURATION} />
      </div>
    );
  }

  return <>{phase}</>;
}

function GameOverScreen({
  correctAnswerCount,
  totalQuestionCount,
  onContinueWatching,
}: {
  correctAnswerCount: number;
  totalQuestionCount: number;
  onContinueWatching: () => void;
}) {
  const { user } = useAppSelector((state) => state.user);

  const isWinner = correctAnswerCount === totalQuestionCount;

  useEffect(() => {
    if (!isWinner) {
      playSound(Sounds["GAME-OVER"]);
    }
  }, [isWinner]);

  return (
    <div className="flex w-full flex-grow flex-col text-primary">
      <div className="my-10 flex h-3/5 min-h-[700px] w-full flex-col place-content-center items-center gap-6 rounded-2xl bg-card p-4">
        <h1 className="text-2xl font-semibold">Game Over</h1>

        <div className="flex h-2/3 w-full flex-col place-content-center items-center gap-4 rounded-2xl border border-border py-32 sm:p-32">
          <Avatar className="h-20 w-20">
            <AvatarImage src={user?.picture} />
            <AvatarFallback>{getInitials(user?.name)}</AvatarFallback>
          </Avatar>
          <p className="py-2 text-lg text-primary">
            You got {correctAnswerCount} of {totalQuestionCount} correct!
          </p>
          {isWinner ? <p>You won the game</p> : <p>That’s your best so far</p>}
        </div>
        {isWinner ? (
          <Button onClick={onContinueWatching}>Check Winners</Button>
        ) : (
          <Button variant={"link"} onClick={onContinueWatching}>
            Continue Watching
          </Button>
        )}
      </div>
    </div>
  );
}

type Result = {
  message: string;
  totalQuestionCount: number;
  winners: Winner[];
  winnerCount: number;
  prizeMoney: number;
  perWinnerPrize: number;
};

function WinnerList({
  result,
  resetGame,
  prizeMoney,
}: {
  result?: Result;
  resetGame: () => void;
  prizeMoney: number;
}) {
  // play a video assets/winner-animation.mp4
  // and then show the List
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { user } = useAppSelector((state) => state.user);

  // check if the current user is winner
  const playerResult = result?.winners?.find(
    // @ts-ignore
    (winner) => (winner["_id"] ?? winner["id"]) === user?.id
  );

  // filter out the current user from the winners list
  const winners = result?.winners?.filter(
    // @ts-ignore
    (winner) => (winner["_id"] ?? winner["id"]) !== user?.id
  );

  useEffect(() => {
    const videoElement = videoRef.current;

    // When the video ends, set the flag to false to show the winners
    const handleVideoEnd = () => {
      setIsVideoPlaying(false);
      playSound(Sounds["WINNER-LIST"]);
    };

    if (videoElement) {
      videoElement.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);

  return (
    <div className="w-full">
      {isVideoPlaying ? (
        <div className="fixed inset-0 z-[1] mx-auto flex w-full place-content-center items-center">
          <div className="fixed inset-0 h-full w-full bg-gradient-to-b from-[#F5BA1C] via-[#F5D961] to-[#ffffff] object-cover"></div>
          <video
            ref={videoRef}
            src="/assets/winner-animation.mp4"
            autoPlay
            className="z-[1] h-full opacity-100"
          />
        </div>
      ) : (
        <div className="flex w-full flex-grow flex-col items-center gap-14">
          {!playerResult && <Player className="mt-10" />}
          <div className="w-fit rounded-full bg-green-400 px-4 py-2 font-semibold text-green-900">
            Game Winners
          </div>
          {/* Current User Result */}
          {playerResult && <Player />}
          <div className="grid w-full grid-cols-2 gap-10 md:grid-cols-3">
            {Array.isArray(winners) ? (
              winners?.map((winner) => (
                <div
                  key={winner.id}
                  className="flex flex-col items-center gap-4">
                  <Avatar className="h-16 w-16">
                    <AvatarImage src={winner.picture} />
                    <AvatarFallback className="font-semibold text-secondary-foreground/70">
                      {getInitials(winner.name)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col items-center text-secondary">
                    <p className="font-semibold">{winner.name}</p>
                    <p className="text-sm">@{winner.username}</p>
                    <p className="font-semibold">
                      ₹{Math.round(result?.perWinnerPrize! * 100) / 100}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <></>
              // <div className="col-span-3 w-full text-center">
              //   <p className="py-10 text-secondary">No winners yet</p>
              // </div>
            )}
            <div className="col-span-3 flex w-full justify-center">
              <Link to="/" className="">
                <Button
                  variant={"default"}
                  className=""
                  onClick={() => {
                    resetGame();
                  }}>
                  <ArrowLeft className="mr-2" /> Go to home
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function Player({ className }: { className?: string }) {
    return (
      <div
        className={cn(
          "flex flex-col place-content-center items-center gap-4",
          className
        )}>
        <Avatar className="h-20 w-20">
          <AvatarImage src={user?.picture} />
          <AvatarFallback className="font-semibold text-secondary-foreground/80">
            {getInitials(user?.name)}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col items-center text-secondary">
          <>
            <p className="font-semibold">{user?.name}</p>
            <p className="text-sm">@{user?.username}</p>
            {playerResult ? (
              <p className="text-xl font-semibold">
                You won ₹{Math.round(result?.perWinnerPrize! * 100) / 100}
              </p>
            ) : (
              <p className="py-4 text-sm font-semibold text-secondary/80">
                Better luck next time
              </p>
            )}
          </>
        </div>
      </div>
    );
  }
}

function NextGameStartTime() {
  const now = new Date();

  // Define the times for the next game
  const gameStartToday = new Date();
  gameStartToday.setHours(19, 0, 0); // 7:00 PM today

  const cutoffTime = new Date();
  cutoffTime.setHours(18, 45, 0); // 6:45 PM today

  function isGameStartToday() {
    if (now <= gameStartToday && now < cutoffTime) {
      return true;
    }
    return false;
  }

  return (
    <div className="flex h-48 flex-col justify-center text-center text-secondary">
      {isGameStartToday() ? (
        <p className="text-lg">Next game starts today at 7:00PM</p>
      ) : (
        <p className="text-lg">Next game starts tomorrow at 7:00PM</p>
      )}
    </div>
  );
}
