
export const QUESTION_DISPLAY_DURATION = 10; // in seconds
export const ANSWER_WAIT_DURATION = 3; // in seconds
export const ANSWER_DISPLAY_DURATION = 5; // in seconds
export const NEXT_QUESTION_WAIT_DURATION = 3; // in seconds


export const Sounds = {
  '15-MIN-TO-30-SEC-COUNTDOWN': "/assets/tick-tock-countdown-from-15 min-to-30-sec.mp3",
  'LAST-30-SECONDS': "/assets/last-30-sec.mp3",
  'PICK-OPTION': "/assets/pick-options.mp3",
  'CORRECT': "/assets/correct-answer.mp3",
  'WRONG': "/assets/wrong-answer.mp3",
  'WINNER-LIST': "/assets/winner-list.mp3",
  'GAME-OVER': "/assets/game-over.mp3",
}