import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Status } from "../base.state";
import { loginWithMobileAsync, verifyOTPAsync } from "./auth.action";



export interface AuthRState {
  phoneNumber?: string;
  status: Status;
  otpStatus: Status
}

const initialState: AuthRState = {
  phoneNumber: undefined,
  status: "idle",
  otpStatus: "idle"
};

const reducers = {
  updateState: (
    state: AuthRState,
    action: PayloadAction<Partial<AuthRState>>
  ) => {
    return { ...state, ...action.payload };
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<AuthRState>) => {
  // SEND OTP
  builder.addCase(loginWithMobileAsync.pending, (state) => {
    state.status = "loading";
  })

  builder.addCase(loginWithMobileAsync.fulfilled, (state, action) => {
    state.status = "success";
    state.phoneNumber = action.meta.arg.phoneNumber;
  })

  builder.addCase(loginWithMobileAsync.rejected, (state) => {
    state.status = "failed";
  })

  // VERIFY OTP
  builder.addCase(verifyOTPAsync.pending, (state) => {
    state.otpStatus = "loading";
  })
  builder.addCase(verifyOTPAsync.fulfilled, (state) => {
    state.otpStatus = "success";
  })
  builder.addCase(verifyOTPAsync.rejected, (state) => {
    state.otpStatus = "failed";
  });


};

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

export const actions = AuthSlice.actions;

const AuthReducer = AuthSlice.reducer;

export default AuthReducer;
