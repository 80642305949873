import React from "react";
import { useEffect, useState } from "react";

interface CountDownTimerProps {
    onCountdownUpdate?: (count: number) => void;
    countdownStartFrom?: number;
}
export function QuizCountDownTimer({
    onCountdownUpdate = (count: number) => { },
    countdownStartFrom = 5,
}: CountDownTimerProps) {
    const [countdown, setCountdown] = useState(countdownStartFrom);

    useEffect(() => {
        // 5 seconds
        const interval = setInterval(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            }
            onCountdownUpdate(countdown - 1);
            if (countdown === 0) {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countdown, onCountdownUpdate]);
    return (
        <div className="flex h-14 w-14 place-content-center items-center rounded-full bg-primary/10 font-semibold ring-8 ring-primary">
            {countdown}
        </div>
    );
}
