import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthApp from "ui/pages/app/auth-app";

const PrivateRoutes = () => {
  let token = localStorage.getItem("token");
  return token ? (
    <AuthApp>
      <Outlet />
    </AuthApp>
  ) : (
    <Navigate to="/auth" />
  );
};

export default PrivateRoutes;

const AuthRoutes = () => {
  let token = localStorage.getItem("token");
  return !token ? <Outlet /> : <Navigate to="/" />;
};

export { AuthRoutes };
