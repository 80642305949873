import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Status } from "../base.state";
import { Game } from "types";
import { getNextGameAsync } from "./game.action";



export interface GameRState {
  game?: Game
  status: Status;
}

const initialState: GameRState = {
  game: undefined,
  status: "idle",
};

const reducers = {
  updateState: (
    state: GameRState,
    action: PayloadAction<Partial<GameRState>>
  ) => {
    return { ...state, ...action.payload };
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<GameRState>) => {
  builder.addCase(getNextGameAsync.pending, (state) => {
    state.status = "loading";
  })

  builder.addCase(getNextGameAsync.fulfilled, (state, action) => {
    state.status = "success";
    state.game = action.payload['game'];
  })

  builder.addCase(getNextGameAsync.rejected, (state) => {
    state.status = "failed";
  })
};

const gameSlice = createSlice({
  name: "Game",
  initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

export const { updateState } = gameSlice.actions;

const gameReducer = gameSlice.reducer;

export default gameReducer;
