import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Status } from "../../base.state";
import { QuizResult, GameWinners } from "types";
import { getGameWinnersAsync, getQuizResultAsync } from "./quiz-result.action";




export interface QuizResultRState {
  // User's quiz result
  quizResult?: QuizResult
  status: Status
  // Game winners
  winnersStatus: Status
  gameWinners?: GameWinners
}

const initialState: QuizResultRState = {
  quizResult: undefined,
  status: "idle",
  winnersStatus: "idle"
};

const reducers = {
  updateState: (
    state: QuizResultRState,
    action: PayloadAction<Partial<QuizResultRState>>
  ) => {
    return { ...state, ...action.payload };
  }
};

const extraReducers = (builder: ActionReducerMapBuilder<QuizResultRState>) => {
  builder.addCase(getQuizResultAsync.pending, (state) => {
    state.status = "loading";
  })

  builder.addCase(getQuizResultAsync.fulfilled, (state, action) => {
    state.status = "success";
    state.quizResult = action.payload;
  })

  builder.addCase(getQuizResultAsync.rejected, (state) => {
    state.status = "failed";
  })

  builder.addCase(getGameWinnersAsync.pending, (state) => {
    state.winnersStatus = "loading";
  })

  builder.addCase(getGameWinnersAsync.fulfilled, (state, action) => {
    state.winnersStatus = "success";
    state.gameWinners = action.payload;
  })

  builder.addCase(getGameWinnersAsync.rejected, (state) => {
    state.winnersStatus = "failed";
  })
};

const quizResultSlice = createSlice({
  name: "quizResult",
  initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

export const actions = quizResultSlice.actions;

const quizResultReducer = quizResultSlice.reducer;

export default quizResultReducer;
