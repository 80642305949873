import React from "react";
import { Button } from "./ui/button";
import { ArrowLeftIcon } from "./icons";
import { Link } from "react-router-dom";

interface BackButtonProps {
  path: string;
}

export default function BackButton({ path }: BackButtonProps) {
  return (
    <Link to={path}>
      <Button
        variant={"outline"}
        size={"icon"}
        className="my-4 h-7 w-11 rounded-full">
        <ArrowLeftIcon
          className="w-11 text-muted-foreground"
          iconProp={{ height: 20, width: 15 }}
        />
      </Button>
    </Link>
  );
}
