import { useAppSelector } from "hooks/redux-hooks";
import { httpClient } from "lib/http-client";
import React, { useEffect, useState } from "react";
import { WinningHistory } from "types/win-history.type";
import BackButton from "ui/components/back-button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "ui/components/ui/tabs";
import MobilePageLayout from "ui/layout/mobile-page-layout";
import CurrentBalance from "./current-balance";
import WinHistory from "./win-history";

export default function BalancePage() {
  const { user } = useAppSelector((state) => state.user);
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );
  const [winHistoryList, setWinHistoryList] = useState<WinningHistory[]>();

  useEffect(() => {
    if (user) {
      fetchWinHistory();
    }
  }, [user]);

  async function fetchWinHistory() {
    setStatus("loading");
    const response = await httpClient.GET<any>(`/game/win-history/my`);
    if (response.data?.winningHistory) {
      setWinHistoryList(response.data.winningHistory);
    }
    setStatus("success");
  }

  return (
    <MobilePageLayout isLoading={status === "loading"}>
      <div className="">
        <div className="m-4 flex place-content-between items-center">
          <BackButton path="../" />
          <h1 className="text-xl font-medium">Balance</h1>
          <p></p>
        </div>
        <div className="">
          <Tabs defaultValue={"current"} className="">
            <TabsList className="w-full justify-center gap-10 rounded-none border-b bg-card">
              <TabsTrigger
                value="current"
                className="w-36 px-5 py-2 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground">
                Current Balance
              </TabsTrigger>
              <TabsTrigger
                value="history"
                className="w-36 px-5 py-2 data-[state=active]:bg-primary data-[state=active]:text-primary-foreground">
                History
              </TabsTrigger>
            </TabsList>
            <TabsContent value="current">
              <CurrentBalance winHistoryList={winHistoryList} />
            </TabsContent>
            <TabsContent value="history">
              <WinHistory winHistoryList={winHistoryList} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </MobilePageLayout>
  );
}
