import logger from "redux-logger";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import AuthReducer from "./reducers/auth/auth.reducer";
import UserReducer from "./reducers/user/user.reducer";
import GameReducer from "./reducers/game/game.reducer";
import QuestionReducer from "./reducers/game/question/question.reducer";
import QuizResultReducer from "./reducers/game/quiz-result/quiz-result.reducer";
const store = configureStore({
  reducer: {
    user: UserReducer,
    auth: AuthReducer,
    game: GameReducer,
    question: QuestionReducer,
    quizResult: QuizResultReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
  // prepend and concat calls can be chained
  // .concat(logger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
