import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "lib/http-client";

export const getQuizResultAsync = createAsyncThunk(
  "quiz-result/getQuizResult",
  async ({ gameId }: { gameId: string }) => {
    const resource = await httpClient.GET<any>(
      `/game/${gameId}/result/my`,
    );
    return resource.data;
  }
);

export const getGameWinnersAsync = createAsyncThunk(
  "quiz-result/getGameWinners",
  async ({ gameId }: { gameId: string }) => {
    const resource = await httpClient.GET<any>(`/game/${gameId}/winners`);
    return resource.data;
  }
);
