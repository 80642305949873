import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { useToast } from "hooks/use-toast";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { verifyOTPAsync } from "store/reducers/auth/auth.action";
import BackButton from "ui/components/back-button";
import Loader from "ui/components/loader.atom";
import { Button } from "ui/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "ui/components/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "ui/components/ui/input-otp";
import MobilePageLayout from "ui/layout/mobile-page-layout";
import { z } from "zod";

const FormSchema = z.object({
  pin: z.string().min(6, {
    message: "Your one-time password must be 4 characters.",
  }),
});

export default function VerifyOTPPage() {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const auth = useAppSelector((state) => state.auth);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: "",
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    dispatch(
      verifyOTPAsync({ otp: data.pin, phoneNumber: auth.phoneNumber })
    ).then((result) => {
      if (verifyOTPAsync.fulfilled.match(result)) {
        localStorage.setItem("token", result.payload.accessToken);
        toast({
          title: "Success",
          description: "OTP verified successfully",
          variant: "success",
        });
        router("/", { replace: true });
      } else if (verifyOTPAsync.rejected.match(result)) {
        toast({
          title: "Error",
          description: result.error.message ?? "Something went wrong",
          variant: "destructive",
        });
      }
    });
  }

  return (
    <MobilePageLayout>
      <div className="m-4">
        <BackButton path="../" />

        <div className="mx-auto flex max-w-80 flex-col">
          <div className="py-10">
            <h1 className="text-xl font-medium">Verify your no.</h1>
            <p className="text-sm">
              Enter the code sent on +91 - {auth.phoneNumber}
            </p>
          </div>
          <div className="my-10 flex flex-col gap-24">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-20">
                <FormField
                  control={form.control}
                  name="pin"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputOTP maxLength={6} {...field} autoFocus>
                          <InputOTPGroup className="mx-auto max-w-80">
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button type="submit">
                  {auth.otpStatus === "loading" ? <Loader /> : "Verify"}
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </MobilePageLayout>
  );
}
