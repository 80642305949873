import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Status } from "../../base.state";
import { Question } from "types";
import { getQuestionAsync, submitAnswerAsync } from "./question.action";



export interface QuestionRState {
  index: number;
  question?: Question
  nextQuestion?: Question
  status: Status | 'gettingNextQuestion'
  answerStatus: 'Idle' | 'Submitting' | 'Failed'
}

const initialState: QuestionRState = {
  index: 0,
  question: undefined,
  status: "idle",
  answerStatus: 'Idle',
};

const reducers = {
  updateState: (
    state: QuestionRState,
    action: PayloadAction<Partial<QuestionRState>>
  ) => {
    return { ...state, ...action.payload };
  },

  setQuestion: (state: QuestionRState) => {
    state.question = state.nextQuestion;
    state.nextQuestion = undefined;
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<QuestionRState>) => {
  builder.addCase(getQuestionAsync.pending, (state, action) => {
    state.status = action.meta.arg.isInitialLoad ? "loading" : "gettingNextQuestion";
  })

  builder.addCase(getQuestionAsync.fulfilled, (state, action) => {
    state.status = "success";
    state.index = action.meta.arg.index + 1;
    if (action.meta.arg.isInitialLoad) {
      state.question = action.payload['question'];
    } else {
      console.log("Next question: ", action.meta.arg, action.payload['question']);
      state.nextQuestion = action.payload['question'];
    }
  })

  builder.addCase(getQuestionAsync.rejected, (state) => {
    state.status = "failed";
  })
  builder.addCase(submitAnswerAsync.pending, (state) => {
    state.answerStatus = "Submitting";
  })
  builder.addCase(submitAnswerAsync.fulfilled, (state) => {
    state.answerStatus = 'Idle'
  })
  builder.addCase(submitAnswerAsync.rejected, (state) => {
    state.status = "failed";
  })
};

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

export const { setQuestion } = questionSlice.actions;

const questionReducer = questionSlice.reducer;

export default questionReducer;
