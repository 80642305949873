import { cn } from "lib/utils";
import React from "react";

interface CountDownTimerProps {
    timeLeft: number;
}

export function GameStartCountDownTimer({
    timeLeft,
}: CountDownTimerProps) {

    return (
        <div
            className={cn(
                `flex flex-col place-content-center items-center justify-center transition-all duration-1000`,
                timeLeft > 15 ? `my-10 gap-2 text-4xl` : "my-10 gap-10 md:p-20 text-7xl"
            )}>
            <p className="text-xl font-semibold">Starts in</p>
            <div className="flex items-center gap-0 font-bold w-full justify-center">
                <span className="px-2">
                    {Math.floor(timeLeft / 60 / 60)
                        .toString()
                        .padStart(2, "0")}
                </span>
                <span>:</span>
                <span className="px-2">
                    {Math.floor((timeLeft / 60) % 60)
                        .toString()
                        .padStart(2, "0")}
                </span>
                <span>:</span>
                <span className="px-2">{(timeLeft % 60).toString().padStart(2, "0")}</span>
            </div>
        </div>
    );
}
