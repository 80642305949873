import clsx from "clsx";
import React from "react";
import { Sounds } from "constants/game.constants";
import { motion } from "framer-motion";
import { playSound } from "lib/functions/play-sound";
import { cn } from "lib/utils";

export default function OptionItem({
  option,
  onSelect = () => {},
  isSelected,
  isCorrect,
  isWrong,
  isSelectionLocked,
}: {
  option: string;
  onSelect: (option: string) => void;
  isSelected: boolean;
  isCorrect: boolean;
  isWrong: boolean;
  isSelectionLocked: boolean;
}) {
  return (
    <motion.div
      whileTap={{ scale: isSelectionLocked ? 1 : 0.95 }}
      transition={{
        type: "spring",
        damping: 20,
        stiffness: 300,
      }}
      className={cn(
        clsx(
          "flex w-full cursor-pointer justify-between rounded-lg border border-primary/10 font-bold",
          "hover:bg-primary/10 hover:text-secondary-foreground",

          {
            "bg-primary/40 ring-2 ring-primary hover:bg-primary/40": isSelected,
            "hover:bg-primary/30": isSelected && isSelectionLocked,

            "bg-green-500 ring-green-500 hover:bg-green-400": isCorrect,
            "ring-green-500 hover:bg-green-500": isCorrect && isSelectionLocked,

            "bg-red-500 text-secondary ring-red-500 hover:bg-red-400": isWrong,
            "hover:bg-red-500 hover:text-secondary hover:ring-red-500":
              isWrong && isSelectionLocked,

            "cursor-not-allowed active:scale-100": isSelectionLocked,
          }
        )
      )}>
      <button
        className={clsx("flex w-full p-2", {
          "cursor-not-allowed": isSelectionLocked,
        })}
        disabled={isSelectionLocked}
        onClick={() => {
          if (!isSelectionLocked) {
            onSelect(option);
            playSound(Sounds["PICK-OPTION"]);
          }
        }}>
        <span>{option}</span>
      </button>
    </motion.div>
  );
}
