import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { useToast } from "hooks/use-toast";
import { getInitials } from "lib/functions/get-initials";
import React, { useEffect } from "react";
import { Status } from "store/reducers/base.state";
import { getUserLastWinningAmountAsync } from "store/reducers/user/user.action";
import { WinningHistory } from "types/win-history.type";
import { Avatar, AvatarFallback, AvatarImage } from "ui/components/ui/avatar";
import { Button } from "ui/components/ui/button";

export default function CurrentBalance({
  winHistoryList,
}: {
  winHistoryList?: WinningHistory[];
}) {
  const [cashOutStatus, setCashOutStatus] = React.useState<Status>("idle");
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { user, lastWinnings, lastWinningAmountStatus } = useAppSelector(
    (state) => state.user
  );
  const totalWin =
    winHistoryList?.reduce((acc, win) => acc + win.userPrizeMoney, 0) || 0;

  useEffect(() => {
    if (lastWinningAmountStatus === "idle") {
      dispatch(getUserLastWinningAmountAsync());
    }
  }, [dispatch, lastWinningAmountStatus]);

  async function handleCashOut() {
    setCashOutStatus("loading");
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/balance-cashout`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Client-Type": "web",
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        setCashOutStatus("success");
        dispatch(getUserLastWinningAmountAsync());
        toast({
          title: "Cash out initiated successfully",
          description: "You will receive your cash in your account shortly",
          variant: "success",
        });
      } else {
        const body = await response.json();
        console.error("Error cashing out", body);
        if (body.message === "Cashout already pending") {
          setCashOutStatus("success");
          toast({
            title: "Message",
            description: "Cashout already pending",
            variant: "success",
          });
        } else {
          setCashOutStatus("failed");
          toast({
            title: "Failed",
            description: "Cashout failed",
            variant: "destructive",
          });
        }
      }
    } catch (error) {
      setCashOutStatus("failed");
      console.error("Error cashing out", error);
    }
  }

  return (
    <div className="m-4 flex flex-col rounded-2xl border border-border p-4">
      <div className="flex flex-grow flex-col place-content-center items-center gap-4">
        <Avatar className="h-20 w-20">
          <AvatarImage src={user?.picture} />
          <AvatarFallback>{getInitials(user?.name)}</AvatarFallback>
        </Avatar>
        <h1 className="text-xl text-primary">
          ₹ {Math.round(totalWin! * 100) / 100}
        </h1>
        <h1 className="text-sm">Lifetime Winning</h1>
      </div>
      <div className="m-4 flex items-center justify-between border-y border-border p-3">
        <p className="text-primary">₹{Math.round(lastWinnings * 100) / 100}</p>
        <p className="text-muted-foreground">Available</p>
      </div>
      {lastWinnings > 0 && (
        <div className="m-4 flex justify-center">
          <Button
            className="rounded-full bg-green-600 px-8"
            onClick={handleCashOut}
            disabled={cashOutStatus === "loading"}>
            {
              {
                idle: "Cash Out",
                loading: "Cashing Out...",
                success: "Cashed Out",
                failed: "Retry",
              }[cashOutStatus]
            }
          </Button>
        </div>
      )}
    </div>
  );
}
