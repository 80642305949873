import { clsx } from "clsx";
import { CheckCheckIcon, XIcon } from "lucide-react";
import React from "react";

interface AnswerStatusProps {
  selectedOption?: string;
  correctAnswer?: string;
}
export function AnswerStatus({
  selectedOption,
  correctAnswer,
}: AnswerStatusProps) {
  console.log({ selectedOption, correctAnswer });
  return (
    <div
      className={clsx("rounded-full px-4 py-2 font-semibold", {
        "bg-green-500": selectedOption === correctAnswer,
        "bg-red-500 ring-red-500": selectedOption !== correctAnswer,
      })}>
      {selectedOption === correctAnswer ? (
        <span className="flex place-content-center items-center gap-1">
          <CheckCheckIcon className="h-4 w-4" />
          Correct
        </span>
      ) : (
        <span className="flex place-content-center items-center gap-1 text-secondary">
          <XIcon className="h-4 w-4" />
          Wrong
        </span>
      )}
    </div>
  );
}
