import { motion } from "framer-motion";
import React from "react";
type Props = {
  value: number;
  max: number;
  width: number;
  radius: number;
};

export function CircularProgressBar({ value, max, width, radius }: Props) {
  const percentage = Math.min(Math.max(value, 0), 100);
  const circumference = 2 * Math.PI * radius;

  const offset = circumference - (percentage / max) * circumference;
  return (
    <div className="relative">
      <svg
        width={width}
        height={width}
        xmlns="http://www.w3.org/2000/svg"
        className="">
        <defs>
          <radialGradient
            id="circle-progress"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(53.1659 -18.1884) rotate(51.1683) scale(267.012 282.957)">
            <stop stopColor="hsl(var(--primary))" />
            <stop offset="1" stopColor="hsl(var(--primary))" stopOpacity="0" />
          </radialGradient>
        </defs>
        <circle
          cx={width / 2}
          cy={width / 2}
          r={radius}
          className="fill-none stroke-secondary stroke-[8px]"
        />

        <motion.circle
          cx={width / 2}
          cy={width / 2}
          r={radius}
          strokeLinecap="round"
          className="fill-none stroke-[url('#circle-progress')] stroke-[8]"
          initial={{
            strokeDashoffset: circumference,
            strokeDasharray: circumference,
          }}
          animate={{ strokeDashoffset: offset }}
          transition={{
            duration: 0.5,
            type: "tween",
            ease: "easeInOut",
          }}
        />
      </svg>
      <div className="absolute inset-0 h-full w-full">
        <div className="flex h-full place-content-center items-center rounded-full font-semibold">
          <motion.text
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              type: "tween",
              ease: "easeInOut",
            }}
            className="text-2xl font-semibold text-primary">
            {percentage}
          </motion.text>
        </div>
      </div>
    </div>
  );
}
