import { clsx } from "clsx";
import { QUESTION_DISPLAY_DURATION, Sounds } from "constants/game.constants";
import { playSound } from "lib/functions/play-sound";
import { cn } from "lib/utils";
import React, { useEffect, useState } from "react";
import { Question } from "types";
import { AnswerStatus } from "ui/components/answer-status";
import { CircularProgressBar } from "ui/components/circular-progress-bar";
import OptionItem from "./option-item";

export default function QuestionPrompt({
  question,
  isSpectator = true,
  wasUserOut = true,
  revealAnswer = false,
  correctAnswer,
  selectedOption,
  setSelectedOption,
  questionTimeLeft,
  resetQuestionTimeLeft,
}: {
  question: Question;
  isSpectator?: boolean;
  wasUserOut?: boolean;
  revealAnswer?: boolean;
  correctAnswer?: string;
  selectedOption?: string;
  setSelectedOption: (option: string) => void;
  questionTimeLeft: number;
  resetQuestionTimeLeft: () => void;
}) {
  if (revealAnswer) {
    if (selectedOption === correctAnswer) {
      playSound(Sounds["CORRECT"]);
    }
    if (selectedOption && selectedOption !== correctAnswer) {
      playSound(Sounds["WRONG"]);
    }
  }
  return (
    <div className="flex h-full w-full flex-grow flex-col p-6 pt-10">
      {isSpectator && (
        <p className="w-full pb-8 text-center text-secondary/60">
          {wasUserOut
            ? "You are a spectator now"
            : "Game has already started, you are a spectator for this game"}
        </p>
      )}
      <div className="flex w-full flex-col place-content-center items-center gap-6 rounded-md bg-card p-10 text-secondary-foreground">
        {revealAnswer ? (
          wasUserOut ? (
            <></>
          ) : (
            <AnswerStatus
              selectedOption={selectedOption}
              correctAnswer={correctAnswer}
            />
          )
        ) : (
          <QuestionTimer
            questionTimeLeft={questionTimeLeft}
            countDownLength={QUESTION_DISPLAY_DURATION}
            resetQuestionTimeLeft={resetQuestionTimeLeft}
          />
        )}

        <p className="py-6 text-center text-2xl font-semibold">
          {question?.question}
        </p>
        <div className="flex w-full flex-col gap-2">
          {question?.options?.map((option, index) => (
            <OptionItem
              key={index}
              option={option}
              onSelect={setSelectedOption}
              isSelected={selectedOption === option}
              isCorrect={
                (option === selectedOption && revealAnswer) ||
                (revealAnswer && option === correctAnswer)
              }
              isSelectionLocked={revealAnswer || isSpectator}
              isWrong={
                selectedOption === option &&
                option !== correctAnswer &&
                revealAnswer
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface QuestionTimerProps {
  questionTimeLeft: number;
  countDownLength: number;
  resetQuestionTimeLeft: () => void;
}
function QuestionTimer({
  questionTimeLeft,
  countDownLength,
  resetQuestionTimeLeft,
}: QuestionTimerProps) {
  const [countdown, setCountdown] = useState(countDownLength);

  useEffect(() => {
    // 5 seconds
    const interval = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  // update the countdown with the question time left
  useEffect(() => {
    console.log("questionTimeLeft: ", questionTimeLeft);
    if (questionTimeLeft >= 0) {
      setCountdown(questionTimeLeft);
      resetQuestionTimeLeft();
    }
  }, [questionTimeLeft]);

  return (
    <CircularProgressBar value={countdown} max={10} width={80} radius={30} />
  );
}
